<template>
  <v-card flat class="xw-full xpb-[60px]" min-height="80vh">
    <AdminOwners></AdminOwners>

    <v-card-title class="xflex xflex-row xjustify-between xgap-2 xitems-center">
      <app-search
        :api="`api/workspaces/${user.team_id}/users-search`"
        item-text="real_name"
        item-value="id"
        class="md:xmax-w-[300px] xmax-w-full"
        @selected="onSelect"
        @cleared="fetchNewUser"
      ></app-search>
      <div
        :class="[
          tabletDown ? 'xw-full' : '',
          mobile ? 'xflex-col' : 'xflex-row',
        ]"
        class="xflex xjustify-end xitems-center xgap-[0.5em]"
      >
        <SyncToSlack
          :block="mobile"
          v-if="user"
          :team-id="user.team_id"
        ></SyncToSlack>
        <btn-tooltip
          :block="mobile"
          color="primary"
          tip="Save changes"
          @click="saveChanges"
        >
          Save Changes
        </btn-tooltip>
      </div>
    </v-card-title>
    <v-card-text>
      <app-table
        :loading="loading"
        :headings="headers"
        :items="items"
        empty-text="No new users found."
      >
        <template v-slot:username="{ item }">
          <avatar size="35" :user="item" with-name></avatar>
        </template>
        <template v-slot:created_at="{ item }">
          <span>{{ item.created_at | format("LL") }}</span>
        </template>
        <template v-slot:type="{ item }">
          <span>{{ item.role ? item.role.normalize_name : "Slack User" }}</span>
        </template>
        <template v-slot:is_paying="{ item }">
          <v-switch
            :disabled="!$can.and('manage_member_subscription')"
            inset
            v-model="item.is_paying"
            :true-value="true"
            color="#19CB40"
            :false-value="false"
          ></v-switch>
        </template>
        <template v-slot:data_center="{ item }">
          <DataCentersSelect
            :disabled="!$can.and('manage_member_subscription')"
            v-model="item.data_centers_id"
            class="xmax-w-[350px]"
          ></DataCentersSelect>
        </template>
      </app-table>
    </v-card-text>
    <!-- <pre>{{ items }}</pre> -->
    <v-card-actions
      v-if="next_page_url"
      class="xflex-col xflex xjustify-center xitems-center xw-full xh-[60px] xabsolute xbottom-0"
    >
      <btn-tooltip
        :loading="loading_more"
        color="primary"
        @click="fetchMoreNewUser"
        tip="Load more..."
      >
        Load more
      </btn-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DataCentersSelect from "@/views/GlobalComponents/Forms/DataCentersSelect.vue";
import AdminOwners from "../components/AdminOwners.vue";

export default {
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["subscription"]),
    payload() {
      return {
        mapping: _.cloneDeep(this.items).map((i) => {
          return {
            id: i.id,
            is_paying: i.is_paying,
            centers: i.data_centers_id,
          };
        }),
      };
    },
    headers() {
      let list = [
        { text: "User Name", value: "username", sortable: true },
        { text: "Date Added", value: "created_at", sortable: true },
        { text: "Email Address", value: "email", sortable: true },
        { text: "Type", value: "type", sortable: false },
        {
          text:
            this.subscription && this.subscription.status === "EXEMPTED"
              ? "Allow Clocking"
              : "Add To Paying",
          value: "is_paying",
          sortable: false,
          tdClass: "xflex xflex-col xjustify-center xitems-center",
        },
      ];
      if (this.isModuleEnabled("data-centers")) {
        list.push({
          text: "Assign Data Center",
          value: "data_center",
          sortable: false,
        });
      }
      return list;
    },
  },
  data() {
    return {
      submiting: false,
      loading: false,
      loading_more: false,
      next_page_url: null,
      items: [],
    };
  },
  created() {
    this.fetchNewUser();
  },
  methods: {
    ...mapMutations(["set_step1"]),
    onSelect(val) {
      this.items = [val];
      this.next_page_url = null;
    },
    fetchNewUser() {
      this.loading = true;
      this.$axios
        .get(
          `api/workspaces/${this.user.team_id}/paginated-users?new=1&withCenters=1`
        )
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMoreNewUser() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
    saveChanges() {
      this.appConfirmation(
        "Save changes?",
        () => {
          this.submiting = true;
          this.$axios
            .post(
              `api/workspaces/${this.user.team_id}/data-centers-assignment`,
              this.payload
            )
            .then(({ data }) => {
              this.appToast("Success! The changes have been saved.", "success");
              this.set_step1(false);
            })
            .finally(() => (this.submiting = false));
        },
        null,
        "Continue"
      );
    },
  },
  components: { DataCentersSelect, AdminOwners },
};
</script>

<style lang="scss" scoped></style>
